<template>
  <div class="validity-rules-preview">
    <Calendar
      ref="commonCalendar"
      :events="parsedValidityPeriods"
      :loading="runningAction"
      @change="calculateValidityPeriods"
    >
      <template #actions="{ loading }">
        <v-spacer />
        <ViolationAlert
          v-if="!loading && hasViolation"
          data-test-id="validityRulesPreviewViolationAlert"
          :violation="violation"
        />
      </template>

      <template #hint>
        <div class="d-flex align-center mb-3 warning--text">
          <v-icon small color="warning" left>mdi-alert</v-icon>
          <div>
            The final result depends on the validity period of the imported
            product
          </div>
        </div>
      </template>

      <template #dialog="{ event }">
        <div class="validity-periods-dialog">
          <v-icon left :color="event.valid ? 'green' : 'red'">
            {{
              event.valid
                ? "mdi-check-circle-outline"
                : "mdi-close-circle-outline"
            }}
          </v-icon>
          <div>
            {{
              event.valid
                ? "Inside the validity period"
                : "Outside the validity period"
            }}
          </div>
        </div>
      </template>
    </Calendar>
  </div>
</template>

<script>
import Calendar from "components/common/templates/Calendar";
import ViolationAlert from "components/common/display-helpers/ViolationAlert";
import validityPeriodsMixin from "mixins/validity-periods-mixin.js";
export default {
  mixins: [validityPeriodsMixin],
  components: {
    Calendar,
    ViolationAlert,
  },
  props: {
    validityRules: {
      type: Array,
      requried: false,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      runningAction: false,
      parsedValidityPeriods: [],
      violation: null,
    };
  },

  watch: {
    validityRules: {
      handler: function () {
        const calendar = this.$refs?.commonCalendar?.$refs?.calendar;
        if (calendar) {
          const start = calendar.lastStart;
          const end = calendar.lastEnd;
          this.calculateValidityPeriods({ start, end });
        }
      },
      deep: true,
    },
  },

  methods: {
    async calculateValidityPeriods({ start, end }) {
      try {
        this.runningAction = true;
        this.violation = null;
        const body = {
          validityRules: this.validityRules,
          fromDate: start?.date,
          untilDate: end?.date,
        };
        const response =
          await this.$store.$coreApi.coreConfigurationApi.previewValidityRules(
            body
          );
        if (!response?.ok) {
          if (response?.status === 400) {
            const violation = await response.json();
            this.violation = violation;
          } else {
            this.violation = {
              status: response?.status ?? 500,
              violation: {
                message:
                  "Something went wrong while calculating validity periods",
              },
            };
          }
          return;
        }
        const validityPeriods = await response.json();

        //Add all dates between from adn until date to the date array
        let dates = [];
        const fromDate = new Date(start.date);
        const untilDate = new Date(end.date);
        this.addDates(fromDate, untilDate, dates, validityPeriods);

        let currentEvent = {
          color: "psblue",
          timed: false,
          valid: true,
        };

        let events = [];
        if (dates.length === 1) {
          //Only one period, so just set the start and end date
          const dateObject = dates[0];
          currentEvent.start = dateObject.date;
          currentEvent.end = dateObject.date;
          currentEvent.valid = dateObject.valid;
          currentEvent.color = dateObject.valid ? "psblue" : "grey";
        } else {
          //Multiple validity periods, so create time ranges
          let previousDate = null;
          let previousDateValid = null;
          dates.forEach(({ date, valid }) => {
            let dateString = this.$formatToISO(date, false);
            const color = valid ? "psblue" : "grey";

            if (previousDate) {
              const diffInDays = this.$getDayDiff(previousDate, date);
              if (
                diffInDays <= 1 &&
                diffInDays >= 0 &&
                valid === previousDateValid
              ) {
                //dates are next to each other or on the same day and have the same validity,
                //so update end date of current event
                currentEvent.end = dateString;
              } else {
                //dates are not next to each other, so push current one and create new event
                if (!currentEvent.end) currentEvent.end = currentEvent.start;
                events.push(currentEvent);
                if (valid !== previousDateValid) {
                  //events have different validity, so set the start date
                  //of the new event to one day after the current
                  const endDate = new Date(currentEvent.end);
                  const newEventStart = this.addDays(endDate, 1);
                  dateString = this.$formatToISO(newEventStart, false);
                }

                currentEvent = {
                  color,
                  timed: false,
                  start: dateString,
                  end: dateString,
                  valid,
                };
              }
            } else {
              currentEvent.start = dateString;
              currentEvent.valid = valid;
              currentEvent.color = color;
            }
            previousDate = date;
            previousDateValid = valid;
          });
        }
        events.push(currentEvent);

        this.parsedValidityPeriods = events;
      } finally {
        this.runningAction = false;
      }
    },
  },

  computed: {
    hasViolation() {
      return !!this.violation;
    },
  },
};
</script>

<style scoped>
.validity-rules-preview {
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.validity-periods-dialog {
  display: flex;
  align-items: center;
  padding: 12px;
}
</style>

